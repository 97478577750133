<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Submissions</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Submissions"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-contactforms' }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Submissions
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="submissions"
            no-data-text="No Archived Submissions found"
          >
            <template v-slot:item.created_at="{ item }">
              {{ item.formatted_dates.created_at }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="orange lighten-4 orange--text"
                    v-on="on"
                    @click="openRestoreDialog(item)"
                  >
                    <v-icon x-small>mdi-delete-empty</v-icon>
                  </v-btn>
                </template>
                <span>Restore Submission</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Restore Submission</v-card-title>
        <v-card-text
          >Are you sure you wish to restore the submission from
          {{ restoreDialog.submission.full_name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestoreDialog"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestoreDialog"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Contact Forms",
          disabled: false,
          exact: true,
          to: { name: "module-contactforms" },
        },
        {
          text: "Archived",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Form Name", value: "form_name" },
        { text: "Page URL", value: "page_url" },
        { text: "Submitted At", value: "created_at" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        submission: {},
        loading: false,
      },
    };
  },

  computed: {
    submissions() {
      let submissions = this.$store.state.contactForms["archivedSubmissions"];

      if (this.searchTerm !== "") {
        submissions = submissions.filter(s => {
          const name = s.full_name.toLowerCase();
          const email = s.email.toLowerCase();
          const formName = s.form_name.toLowerCase();
          const pageUrl = s.page_url.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            name.includes(searchTerm) ||
            email.includes(searchTerm) ||
            formName.includes(searchTerm) ||
            pageUrl.includes(searchTerm)
          );
        });
      }

      return submissions;
    },
  },

  methods: {
    openRestoreDialog(submission) {
      this.restoreDialog.submission = submission;
      this.restoreDialog.open = true;
    },

    resetRestoreDialog() {
      this.restoreDialog.open = false;
      this.restoreDialog.submission = {};
      this.restoreDialog.loading = false;
    },

    saveRestoreDialog() {
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("contactForms/restoreSubmission", {
          appId: this.$route.params.id,
          submissionId: this.restoreDialog.submission.id,
        })
        .then(() => {
          this.resetRestoreDialog();
        })
        .catch(() => {
          this.restoreDialog.loading = false;
        });
    },
  },
};
</script>
